import React, { PropsWithChildren } from "react"
import { AppProvider } from "@providers/app"
import { ThemeProvider } from "@providers/theme"
import { ConfigProvider } from "@providers/config"
import { ShopifyProvider } from "@providers/shopify"
import { LocationProvider } from "@providers/location"
import { TrackingProvider } from "@providers/tracking"
import { CustomerProvider } from "@providers/customer"
import { CheckoutProvider } from "@providers/checkout"
import { WishlistProvider } from "@providers/wishlist"
import { GiftRegistryProvider } from "@app/providers/giftRegistry"
import { RecentlyViewedProvider } from "@app/providers/recentlyViewed"
import { loadWebbridge, WebbridgeProvider } from "@tapcart/webbridge-react"

const webbridgeClient = loadWebbridge({ test: false })

const Providers = ({ children }: PropsWithChildren) => (
  <LocationProvider>
    <ConfigProvider>
      <ShopifyProvider>
        <AppProvider>
          <CustomerProvider>
            <GiftRegistryProvider>
              <CheckoutProvider>
                <RecentlyViewedProvider>
                  <WishlistProvider>
                    <TrackingProvider>
                      <WebbridgeProvider webbridgeClient={webbridgeClient}>                        
                        <ThemeProvider>{children}</ThemeProvider>
                      </WebbridgeProvider>
                    </TrackingProvider>
                  </WishlistProvider>
                </RecentlyViewedProvider>
              </CheckoutProvider>
            </GiftRegistryProvider>
          </CustomerProvider>
        </AppProvider>
      </ShopifyProvider>
    </ConfigProvider>
  </LocationProvider>
)

export default Providers
